<template>
<div align="start">
  <v-card v-if="hs00">
    <v-card-text class="white">
      <p class="text-subtitle-1 text--primary">
        데이터 소스 (2015년 1월 ~)
      <ul>
        <li>상장법인목록: KRX</li>
        <li>재무제표: DART</li>
        <li>주가시세: KRX</li>
        <li>시장지표: Investing.com</li>
      </ul>
      </p>
      <br>
      <p class="text-subtitle-1 text--primary">
        분기 재무제표 반영 기준 : 3월말(4분기), 5월말(1분기), 8월말(2분기), 11월말(3분기)
      </p>
      <br>
      <p class="text-subtitle-1 text--primary">
        백테스트 가정 
        <ul>
          <li>일반 테스트 기간 : 테스트 시작 전월말부터 현재일까지</li>
          <li>월간 수익율 : 예) 2021년 10월 -> 2021년 9월말 종가 ~ 2021년 10월말 종가 기준</li>    
          <li>전문가 전략: 테스트 기간(2016-01-01 부터 현재일까지), 초기투자금(1000만원), 편입종목(10개), 리벨런싱 주기(반기)</li>
          <li>거래비용 기준 : 증권거래세: 0.23%, 거래수수료: 0.015%</li>
        </ul>
      </p>
    </v-card-text>
  </v-card>

  <v-card v-if="he00">
    <v-card-text class="white">
      <p class="text-subtitle-1 text--primary"  dense>
        데이터 소스 (1993년 2월 ~)
      </p>
      <p class="text-subtitle-1 text--primary">
        <ul>
          <li>ETF: Investing.com</li>
        </ul>
      </p>
      <br>
      <p class="text-subtitle-1 text--primary"  dense>
        포트폴리오 구성은 현재 미국 ETF만 가능
      </p>
      <p class="text-subtitle-1 text--primary">
        백테스트 가정 
        <ul>
          <li>가격: 월 최초거래일 종가</li>
          <li>리벨런싱주기: 기본 1개월 (분기, 반기, 년단위 조정 가능</li>
          <li>시작일: 포트폴리오에 편입된 ETF 중 가장 최근 데이터 수집일 기준</li>
          <li>거래비용: 거래당 슬리피지(slippage) 0.10% (0.20% 매도/매수기준)</li>
          <li>현금수익율: 동적자산 배분 시 현금수익율은 미국 국채 1개월 수익율 기준 (United States 1-Month Bond Yield') </li>
        </ul>      
      </p>
    </v-card-text>
  </v-card>

  <div v-if="hee4">
    <div class="text-h6" align="start"> 60/40 포트폴리오 </div>
    <v-divider></v-divider>
    <br>
    <ul>
      <li>기대 연복리수익율: 8% 정도</li>
      <li>포함 자산: 미국주식(SPY), 미국중기국채(IEF)</li>
      <li>매수 전략: SPY에 60%, IEF에 40% 투자</li>
      <li>매도 전략: 연 1회 리밸런싱</li>
    </ul>
  </div>
  <div v-if="hee5">
    <div class="text-h6" align="start"> 영구 포트폴리오 </div>
    <v-divider></v-divider>
    <br>
    <ul>
      <li>기대 연복리수익율: 8% 정도</li>
      <li>포함 자산: 미국주식(SPY), 미국중기국채(IEF), 금(GLD), 미국 초단기 국채(BIL)</li>
      <li>매수 전략: 4개 ETF에 자산을 4등분</li>
      <li>매도 전략: 연 1회 리밸런싱</li>
    </ul>
  </div>
  <div v-if="hee6">
    <div class="text-h6" align="start"> 사계절 포트폴리오 (All-Seasins Portfolio) </div>
    <v-divider></v-divider>
    <br>
    <ul>
      <li>기대 연복리수익율: 8~10% 정도</li>
      <li>포함 자산: 미국 주식(SPY), 미국 중기국채(IEF), 금(GLD), 미국 장기 국채(TLT), 원자재(DBC)</li>
      <li>매수 전략: 자산을 SPY 30%, IEF 15%, TLT 40%, GLD/DBC에 각 7.5% 배분</li>
      <li>매도 전략: 연 1회 리밸런싱</li>
    </ul>
  </div>
  <div v-if="hee7">
    <div class="text-h6" align="start"> 올웨더 포트폴리오 (All-Weather Portfolio) </div>
    <v-divider></v-divider>
    <br>
    <ul>
      <li>기대 연복리수익율: 8~10% 정도</li>
      <li>포함 자산: 미국 주식(SPY), 미국외 선진국 주식(EFA), 신흥국주식(EEM), 원자재(DBC), 금(GLD),
        미국 제로쿠폰 장기국채(EDV), 물가연동채권(만기 15년 이상)(LTPZ), 미국 회사채(LQD), 신흥국 채권(EMLC)
      </li>
      <li>매수 전략: 자산을 위 순서대로 12, 12, 12, 7, 6, 18, 18, 7, 7% 배분</li>
      <li>매도 전략: 연 1회 리밸런싱</li>
    </ul>
  </div>

  <div v-if="hee10">
    <div class="text-h6" align="start"> GTAA 5(Global Tactical Asset Allocation5) - 미베인 파버 </div>
    <v-divider></v-divider>
    <br>
    <ul>
      <li>기대 연복리수익율: 8~10% 정도</li>
      <li>포함 자산: 미국 주식(SPY), 미국외 선진국 주식(EFA), 미국 중기채(IEF), 원자재(DBC), 부동산(VNQ) </li>
      <li>매수 전략: 각 ETF에 자산의 20%씩 투입, 각 ETF 가격이 10개월 이동평균보다 높으면 각 ETF 보유,
        아니면 현금 보유 </li>
      <li>매도 전략: 연 1회 리밸런싱</li>
    </ul>
  </div>

  <div v-if="he01">
    <div class="text-h6" align="start"> 주요 자산군 </div>
    <v-divider></v-divider>
    <br>
    <v-simple-table
      dense
    >
      <template v-slot:default>
      <thead>
        <tr><th class="text-left">자산군</th><th class="text-left">자산명</th><th class="text-left">티커</th></tr>
      </thead>
      <tbody>
        <tr><td>글로벌 주식</td><td>Global Stocks</td><td>VT</td></tr>
        <tr><td>미국주식 S&P500</td><td>SPDR S&P 500</td><td>SPY</td></tr>
        <tr><td>미국주식 나스닥</td><td>Invesco QQQ Trust</td><td>QQQ</td></tr>
        <tr><td>미국 대형 가치주</td><td>iShares Russell 1000 Value</td><td>IWD</td></tr>
        <tr><td>미국 소형 가치주</td><td>iShares Russell 2000 Value</td><td>IWN</td></tr>
        <tr><td>미국 소형주</td><td>iShares Russell 2000</td><td>IWM</td></tr>
        <tr><td>선진국 주식</td><td>iShares MSCI EAFE</td><td>EFA</td></tr>        
        <tr><td>유럽 주식</td><td>Vanguard FTSE Europe</td><td>VGK</td></tr>
        <tr><td>일본 주식</td><td>	iShares MSCI Japan</td><td>EWJ</td></tr>
        <tr><td>개발도상국 주식</td><td>	iShares MSCI Emerging Markets ETF</td><td>EEM</td></tr>
        <tr><td>미국 초장기 국채</td><td>	Vanguard Extended Duration</td><td>EDV</td></tr>
        <tr><td>미국 장기 국채</td><td>	iShares 20+ Year Treasury Bond</td><td>TLT</td></tr>
        <tr><td>미국 중기 국채</td><td>iShares 7-10 Year Treasury Bond</td><td>IEF</td></tr>
        <tr><td>미국 단기 국채</td><td>iShares 1-3 Year Treasury Bond</td><td>SHY</td></tr>
        <tr><td>미국 초단기 국채(현금)</td><td>SPDR Barclays 1-3 Month T-Bill</td><td>BIL</td></tr>
        <tr><td>미국 물가연동채</td><td>PIMCO 15+ Year US TIPS Exchange-Traded</td><td>LTPZ</td></tr>
        <tr><td>미국 회사채</td><td>iShares iBoxx $ Investment Grade Corporate Bond</td><td>LQD</td></tr>
        <tr><td>미국 하이일드 채권</td><td>iShares iBoxx $ High Yield Corporate Bond</td><td>HYG</td></tr>
        <tr><td>미국 채권</td><td>iShares Core US Aggregate Bond</td><td>AGG</td></tr>
        <tr><td>글로벌 국채</td><td>SPDR Barclays International Treasury Bond</td><td>BWX</td></tr>
        <tr><td>개발도상국 채권</td><td>VanEck Vectors JP Morgan EM Local Currency Bd</td><td>EMLC</td></tr>
        <tr><td>금</td><td>SPDR Gold Shares</td><td>GLD</td></tr>
        <tr><td>원자재</td><td>PowerShares DB Commodity Tracking</td><td>DBC</td></tr>
        <tr><td>부동산(미국리츠)</td><td>Vanguard REIT</td><td>VNQ</td></tr>
      </tbody>
      </template>
    </v-simple-table>>
  </div>

  <div v-if="he02">
    <br>
    <span class="d-flex justify-start text-h6"> 자산배분의 3대 원칙 </span>
    <v-divider></v-divider>
    <br>
    <ol>
      <li>장기적으로 우상향하는 자산군을 보유한다.</li>
      <li>상관성이 낮은 자산군을 보유한다. </li>
      <li>자산군별 변동성을 비슷하게 유지한다.</li>
    </ol>
    <br><br>
    <span class="d-flex justify-start text-h6"> 경제상황별 잘나가는 자산군 </span>
    <v-divider></v-divider>
    <br>
    <v-simple-table
      dense
    >
      <thead>
        <tr><th class="text-start">변동성</th><th class="text-start">고물가/고성장</th><th class="text-start">고물가/저성장</th>
          <th class="text-start">저물가/고성장</th><th class="text-start">저물가/저성장</th></tr>
      </thead>
      <tbody>
        <tr><td>아주높음</td><td>개발도상국 주식</td><td></td><td></td><td></td></tr>
        <tr><td>높음</td><td>국제부동산</td><td></td><td>선진국 주식</td><td></td></tr>
        <tr><td>보통</td><td>금/원자제</td><td>금/원자제</td><td>선진국 부동산</td><td>금</td></tr>
        <tr><td>낮음</td><td>개발도상국 채권</td><td>개발도상국 채권</td><td>선진국 중기국채</td><td>선진국 장기국채</td></tr>
        <tr><td>아주낮음</td><td>물가연동채</td><td>물가연동채</td><td>선진국 회사채</td><td></td></tr>
      </tbody>
    </v-simple-table>
    <br>
    <span class="d-flex justify-start font-weight-bold"> ○ 공격형 투자방법 </span>
    <br>
    <ul>
      <li>경쟁상황별로 각각 잔산을 한개씩 포함</li>
      <li>변동성이 가장 높은 자산군 선택</li>
      <li>변동성이 상대적으로 낮은 자산은 비중을 높게 설정</li>
      <li>예) 개발도상국주식(16%), 금/원자재(12%), 선진국주식(20%), 선진국장기국채(52%)</li>
    </ul>
    <br>
    <span class="d-flex justify-start font-weight-bold"> ○ 안정형 투자방법 </span>
    <br>
    <ul>
      <li>경쟁상황별로 채권에만 투자</li>
      <li>변동성이 상대적으로 낮은 자산은 비중을 높게 설정</li>
      <li>예) 개발도상국채권(20%), 물가연동채(25%), 선진국중기국채(30%), 선진국장기국채(25%)</li>
    </ul>
  </div>


</div>
</template>

<script>  
  export default {
    props: [ 'hs00', 'he00', 'he01', 'he02', 'hee4', 'hee5', 'hee6', 'hee7', 'hee10' ],
    data () {
      return {
          
      }
    },
  }
</script>